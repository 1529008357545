<template>
<div class="portfolio-item">
  <nuxt-img v-if="thumbnail" provider="strapi" :src="`${thumbnail.hash}${thumbnail.ext}`"/>
  <nuxt-img v-else src="/project_fallback.png"/>
  <div class="portfolio-item__info p-small column--centered text--center justify-center">
    <h5 class="text--uppercase">{{ data.thumbnail_subtitle }}</h5>
    <h4 class="heading--small">{{ data.title }}</h4>
    <TextClamp :text="data.thumbnail_text" :max-lines="4" max-height="6.2rem"></TextClamp>
    <ButtonTextLink class="mt-2 text--white" :to="`/portfolio/${data.slug}`">Bekijk meer</ButtonTextLink>
  </div>
</div>
</template>

<script lang="ts">
import useStrapiHelper from "~~/composables/strapi";

const fallback = {
  title: 'Project Titel',
  thumbnail_subtitle: 'Project subtitel',
  thumbnail_text: 'Lorem ipsum.',
  slug: '#',
  createdAt: '',
  updatedAt: '',
  publishedAt: ''
}
</script>

<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => fallback
  },
});

const { flattenObject } = useStrapiHelper();

const thumbnail = computed(() => props.data?.thumbnail?.data ? flattenObject(props.data?.thumbnail) : false);

// const clamped = ref<HTMLDivElement>();

// onMounted(() => {
//   if (clamped.value) {
//     const height = clamped.value.scrollHeight;
//     const heightRem = height / 16;

//     if (heightRem > 6.2) {
//       clamped.value.classList.add('overflown');
//     }
//   }
// })
</script>

<style lang="scss">
.portfolio-item {
  width: 100%;
  max-width: 33rem; 
  height: 22rem;
  background: $lav_blue;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transition: all 235ms ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .portfolio-item__info {
      opacity: 1;
    }
  }

  @include breakpoint(null, 400px) {
    height: 16rem;
  }
}

.portfolio-item__info {
  position: relative;
  transition: all 235ms ease-in-out;
  opacity: 0;
  background: rgba($violet_blue, 0.68);
  height: 100%;
  width: 100%;

  @include breakpoint(null, 400px) {
    p {
      font-size: 0.8rem;
    }

    .mt-2 {
      margin-top: 1rem;
    }
    
  }
}

// .clamped {
//   overflow: hidden;
//   max-height: 6.2rem;

//   &.overflown::after {
//     content: '...';
//   }
// }

.text-clamp {
  line-height: 1.55;
  margin-top: 2rem;
}
</style>
